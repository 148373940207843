<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <router-link to="/config" class="back-link router-link-active"><svg data-v-231527a2="" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-231527a2="" d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z" fill="#0033A0"></path></svg> назад </router-link>

          <h1>Настройка рейтингов 2023</h1>

          <table class="config-list-table">
            <thead>
            <tr>
              <th>Id конфигурации</th>
              <th>Период мониторинга</th>
              <th>Тип мониторинга</th>
              <th>Дата создания</th>
              <th>Дата изменения</th>
              <th></th><th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="config in configs" :key="config.id">
              <td>{{ config.id }}</td>
              <td>{{ periods.find(e=>e.value===config.period)?.text}}</td>
              <td>{{ monitoringTypes.find(e=>e.value===config.monitoringType)?.text }}</td>
              <td>{{ config.creationDateTime | dateFormat }}</td>
              <td>{{ config.modificationDateTime | dateFormat }}</td>
              <td>
                <router-link :to="'/config/ratings2023ConfigEdit/'+config.id">
                  <div class="edit">
                    <v-icon class="icon-item icon-note-edit-outline"></v-icon>
                  </div>
                </router-link>
              </td>
              <td><div class="edit">
                <v-icon color="#0033A0" @click="deleteConfig(config.id)" class="icon-item icon-trash-can"></v-icon>
              </div>
              </td>

            </tr>
            </tbody>
          </table>

          <div class="save-buttons">
            <v-btn color="primary" @click="$router.push('/config/ratings2023ConfigEdit/')">Создать конфигурацию</v-btn>
          </div>

        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

import {deleteConfigById, loadAllConfigs, monitoringTypes, periods} from "@/modules/Rating2023Config";

export default {
  name: "Rating2023Config",
  props: {},
  components: {},
  data() {
    return {
      monitoringTypes: monitoringTypes,
      configs: [],
      periods: periods,
      apiLoaded: false,
      errorText: null,
    };
  },
  methods: {
    async updateData() {
      this.configs = await loadAllConfigs()
      this.sortConfigsById()
    },

    sortConfigsById() {
      this.configs.sort((a, b) => (a.id > b.id) ? 1 : -1)
    },

    async deleteConfig(id){
      if(!confirm('Вы действительно хотите удалить конфигурацию?')) return
      await deleteConfigById(id)
      await this.updateData()
      this.$forceUpdate()
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    await this.updateData()
    this.apiLoaded=true
  }
};
</script>
